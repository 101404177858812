import React from "react";

export default function GetStartedContent() {
  return (
    <div className="relative pt-32 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true"></div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Getting started
            </span>
          </h1>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <h3>1. Install the application to your Slack workspace</h3>
          <p>
            Click{" "}
            <a
              className="font-medium text-indigo-600 hover:text-indigo-500"
              href="https://meet.projectundefined.com/install"
            >
              here
            </a>{" "}
            to install the app and grant the necessary permissions to use it in your Slack.
          </p>
          <h3>2. Authenticate your account</h3>
          <p>When you first use /meet, you will be asked to authenticate your Google account.</p>
          <h3>3. Use /meet in any channel or conversation</h3>
          <p>Generate meet links using the /meet command in Slack.</p>
          <h3>4. Join meetings</h3>
          <p>
            Join meetings always with the correct Google account (no longer switching between work
            and personal!) and get your Slack status auto updated.
          </p>
        </div>
      </div>
    </div>
  );
}
